import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TacticalImg from "../components/playerImage"

const bestManagers = () => {
  return (
    <Layout>
      <SEO
        title="Best Managers in PES 2021"
        description="Find out who the best Managers are in PES 2021 myClub, for both counter-attacking and possession-based football."
      />
      <div className="container">
        <h1>The Best Managers in PES 2021</h1>
        <ul className="breadcrumb" style={{ paddingTop: "1rem" }}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>Best Managers</li>
        </ul>
        <p>
          Finding the perfect manager is always one of the most exciting tasks
          in each edition of PES. You may start with a formation in mind, but
          suddenly you get a good player that forces you to change tactics so
          that you can use him. When things finally click, it's pretty
          satisfying!
        </p>
        <p>
          This year, Konami rewarded early adopters with plenty of superstar
          players from the beginning, so I found it less challenging to pick a
          formation that fits my style. Counter-attacking still feels
          overpowered, just like last year, but I'm a fan of possession-based
          football, so I will offer you some good suggestions for both styles.
        </p>
        <p>
          This is simply my personal opinion, so make sure you keep trying out
          different formations/tactics if you're not happy. Have a look at my
          full <Link to="/managers/">Manager List for PES 2021</Link> for
          inspiration.
        </p>
        <p>
          As a reminder, have a look at all the{" "}
          <Link to="/advanced-instructions/">Advanced Instructions</Link> you
          can use, as well as all the{" "}
          <Link to="/playing-styles/">Playing Styles</Link> players can have for
          better comprehension.
        </p>
        <p>
          <small>
            If you're looking for the{" "}
            <Link to="/best-managers-2020/">best managers in PES 2020</Link>, go
            here.
          </small>
        </p>
        <h2 className="bullet">V. Dos Santos (4-3-3)</h2>
        <Link to="/managers/2021/v-dos-santos/">
          <TacticalImg
            src={`2021/v-dos-santos-offensive-tactic.jpg`}
            alt={`V. Dos Santos Offensive Tactic in PES 2021 myClub`}
          />
        </Link>
        <p>
          <small>
            <Link to="/managers/2021/v-dos-santos/">
              V. Dos Santos - Full Profile
            </Link>
          </small>
        </p>
        <p>
          If you're looking for the classic "4-3-3" formation that many PES fans
          love, you will find dozens of options in my{" "}
          <Link to="/managers/">Manager List</Link>. This is just one
          suggestion, but most will share the same philosophy, just different
          "Support Range", "Defensive Line", and "Compactness" numbers.
        </p>
        <p>
          In this formation, picking the right players for the midfield is key.
          After a lot of experimenting, I feel like the best option is to have 2
          physical Box-to-Box CMFs (with good stamina), along with a DMF with
          the "Anchor Man" playing style.
        </p>
        <p>
          While attacking, players will be well spread out in the field, so you
          can go for long balls to set up counter attacks, preferably to the
          wingers. You can then serve your striker to finish things off.
        </p>
        <p>
          Without the ball, I usually prefer to go for the "Deep Defensive Line"
          instruction, so that my defense drops deeper, and I use defensive
          full-backs to have many players in my own box.
        </p>
        <p>
          This is a very balanced formation and should be one of the first to
          try out when you're new to the game, as it provides a lot of
          flexibility.
        </p>
        <h3>Alternatives to V. Dos Santos</h3>
        <p>
          This is such a popular option that you will find similar offerings in
          the database at any given time, for both "Possession" and "Counter
          Attack" attacking styles.
        </p>
        <p>Here are some other 4-3-3 managers for you to consider:</p>
        <ul className="grid-list">
          <li>
            <Link to="/managers/2021/y-grimault/">Y. Grimault</Link>
          </li>
          <li>
            <Link to="/managers/2021/g-southgate/">G. Southgate</Link>
          </li>
          <li>
            <Link to="/managers/2021/j-laporta/">J. Laporta</Link>
          </li>
          <li>
            <Link to="/managers/2021/f-inzaghi/">F. Inzaghi</Link>
          </li>
          <li>
            <Link to="/managers/2021/m-preuss-2/">M. Preuss (2)</Link>
          </li>
          <li>
            <Link to="/managers/2021/p-boer-2/">P. Boer (2)</Link>
          </li>
        </ul>
        <h2 className="bullet">E. Di Francesco (4-3-1-2)</h2>
        <Link to="/managers/2021/e-di-francesco/">
          <TacticalImg
            src={`2021/e-di-francesco-offensive-tactic.jpg`}
            alt={`E. Di Francesco Offensive Tactic in PES 2021 myClub`}
          />
        </Link>
        <p>
          <small>
            <Link to="/managers/2021/e-di-francesco/">
              E. Di Francesco - Full Profile
            </Link>
          </small>
        </p>
        <p>
          <Link to="/managers/2020/fernando-santos/">Fernando Santos</Link> was
          one of the most popular managers last year until Konami decided to
          drastically change most of his instructions. If you're looking for an
          alternative for 2021, there are some good managers to keep in mind. E.
          Di Francesco is one of them.
        </p>
        <p>
          This formation lacks wingers, so your best bet is to attack through
          the middle. You can go for short or long passes, as you will find your
          players well spread out in the pitch.
        </p>
        <p>
          If you have an AMF with good passing skills, you can quickly create
          chances on the counter with long passes to your 2 strikers. Then, you
          can also go for 1-2s between them.
        </p>
        <p>
          His defensive line isn't that high, so you shouldn't be as vulnerable
          to counters as other similar formations.
        </p>
        <h3>Alternatives to E. Di Francesco</h3>
        <p>
          If you can't find Di Francesco in the database at the time, try to
          look for other Italian managers with the same formation - I noticed
          they share plenty of the same specs.
        </p>
        <p>Here are some worthy alternatives:</p>
        <ul className="grid-list">
          <li>
            <Link to="/managers/2021/p-padalino/">P. Padalino</Link>
          </li>
          <li>
            <Link to="/managers/2021/c-macellari/">C. Macellari</Link>
          </li>
          <li>
            <Link to="/managers/2021/b-rice/">B. Rice</Link>
          </li>
        </ul>
        <h2 className="bullet">Rui Vitória (4-2-1-3)</h2>
        <Link to="/managers/2021/rui-vitoria/">
          <TacticalImg
            src={`2021/rui-vitoria-offensive-tactic.jpg`}
            alt={`Rui Vitória Offensive Tactic in PES 2021 myClub`}
          />
        </Link>
        <p>
          <small>
            <Link to="/managers/2021/rui-vitoria/">
              Rui Vitória - Full Profile
            </Link>
          </small>
        </p>
        <p>
          <Link to="/managers/2020/n-kovac/">
            Niko Kovač was one of my favorite managers last year
          </Link>
          , so I waited for a while until I found the perfect replacement. This
          is it: if you enjoyed Kovač, Rui Vitória is a formidable successor and
          a great fit for fans of possession-based football.
        </p>
        <p>
          I like to use pacey wingers (both Roaming Flank or Prolific Wingers
          will do) and a strong Centre Forward to hold the ball and pass to
          them. This is not a good formation for fans of 1-2s, although you can
          go for an AMF with the "Hole Player" playing style to have more
          options close to goal.
        </p>
        <p>
          In the midfield, I switch one of the DMFs to a CMF that can work as an
          Orchestrator or Box-to-Box, and keep the other as a defensive-minded
          midfielder that can play as an Anchor Man. Otherwise, you may feel
          that this team is lacking numbers on the defense, when you're out of
          possession.
        </p>
        <p>
          The defensive line is set quite high, so I usually use the "Deep
          Defensive Line" advanced instruction with this manager, unless I find
          a big gap between my defenders and the opposition's attack.
        </p>
        <p>
          Just like last year, I prefer to use 2 full-backs with the "Defensive
          Full-back" playing style, or apply the "Defensive" advanced
          instruction to at least one of them.
        </p>
        <h3>Alternatives to Rui Vitória</h3>
        <p>
          You probably won't find Rui Vitória available very often, so here are
          some managers that share most of his traits:
        </p>
        <ul className="grid-list">
          <li>
            <Link to="/managers/2021/t-bindewald/">T. Bindewald</Link>
          </li>
          <li>
            <Link to="/managers/2021/g-cochetti/">G. Cochetti</Link>
          </li>
          <li>
            <Link to="/managers/2021/j-laporta-2/">J. Laporta (2)</Link>
          </li>
        </ul>
        <h2 className="bullet">H. Flick (4-2-2-2)</h2>
        <Link to="/managers/2021/h-flick/">
          <TacticalImg
            src={`2021/h-flick-offensive-tactic.jpg`}
            alt={`H. Flick Offensive Tactic in PES 2021 myClub`}
          />
        </Link>
        <p>
          <small>
            <Link to="/managers/2021/h-flick/">H. Flick - Full Profile</Link>
          </small>
        </p>
        <p>
          Hans-Dieter Flick won everything with his superb Bayern side last
          year, and if you're looking for a very solid team that constantly
          chokes the opposition with his high defensive line, this may be the
          right manager for you.
        </p>
        <p>
          Defensively, I noticed I did not concede many scoring chances per
          game. The centre is pretty crowded with 2 CMFs/DMFs, and if you notice
          your opponent opts to attack through the flanks, you can use your LMF
          and RMF as a second line of defense on wide areas.
        </p>
        <p>
          Offensively, I felt that you won't have that many chances per game
          (unlike Bayern), as I feel there aren't many attacking players in the
          last third of the pitch. With Flick, you will have to be clinical when
          in front of goal.
        </p>
        <p>
          If you enjoy quick 1-2s in the box, this formation will work. I
          recommend combining 2 players with different attributes, such as a
          strong CF with a pacey Second Striker, or a SS with the "Dummy Runner"
          playing style, like Thomas Müller or Roberto Firmino.
        </p>
        <p>
          For some reason, I found that many goal-scoring chances arise in the
          second half when the wingers finally start to appear in front of goal.
          Make sure you introduce some fresh legs if you realize the opponents'
          defensive line is starting to get tired, as that can be game-changing
          for the last 30 minutes.
        </p>
        <h3>Alternatives to H. Flick</h3>
        <p>
          H. Flick should pop up often, as he is one of the top managers to
          choose from, but if you're not lucky enough to find his 4-2-2-2
          formation, here are some similar solutions:
        </p>
        <ul className="grid-list">
          <li>
            <Link to="/managers/2021/c-valbuena/">C. Valbuena</Link>
          </li>
          <li>
            <Link to="/managers/2021/c-carvalhal/">C. Carvalhal</Link>
          </li>
          <li>
            <Link to="/managers/2021/e-walsh/">E. Walsh</Link>
          </li>
          <li>
            <Link to="/managers/2021/s-conceicao/">S. Conceição</Link>
          </li>
          <li>
            <Link to="/managers/2021/d-deschamps/">D. Deschamps</Link>
          </li>
        </ul>
        <h2 className="bullet">A. Rauzzini (3-2-2-3)</h2>
        <Link to="/managers/2021/a-rauzzini/">
          <TacticalImg
            src={`2021/a-rauzzini-offensive-tactic.jpg`}
            alt={`A. Rauzzini (Marco Giampaolo) Offensive Tactic in PES 2021 myClub`}
          />
        </Link>
        <p>
          <small>
            <Link to="/managers/2021/a-rauzzini/">
              A. Rauzzini - Full Profile
            </Link>
          </small>
        </p>
        <p>
          For the fans of 3 at the back, there are plenty of options that you
          may like.
        </p>
        <p>
          A. Rauzzini is one of them. 3-2-2-3 is a rather popular formation this
          year, and I see it as high risk / high reward; you will score many,
          but you will rarely keep a clean sheet.
        </p>
        <p>
          While attacking, I suggest using your Centre Forward as the player who
          holds the ball up front and supplies deadly balls to the 2 Wingers
          (who should be pacey and ideally with the "Roaming Flank" playing
          style).
        </p>
        <p>
          I wouldn't pick very offensive-minded players for the LMF and RMF
          role, to add some consistency to the midfield, and they should have
          very good stamina to last all game.
        </p>
        <p>
          You can switch to 2 DMFs to compensate for the lack of men in the
          defense, but for most players, I believe that one DMF with the
          "Anchor" Man playing style will be enough.
        </p>
        <p>
          Playing with this sort of formation comes with a learning curve, but
          is a nice breath of fresh air and can be the antidote for many
          opposing formations as well.
        </p>
        <h3>Alternatives to A. Rauzzini</h3>
        <p>
          I found many alternatives with a similar 3-2-2-3 formation. Have a
          look at these:
        </p>
        <ul className="grid-list">
          <li>
            <Link to="/managers/2021/lito-vidigal/">Lito Vidigal</Link>
          </li>
          <li>
            <Link to="/managers/2021/d-mcinnes/">D. McInnes</Link>
          </li>
          <li>
            <Link to="/managers/2021/a-garcia/">A. García</Link>
          </li>
        </ul>
      </div>
    </Layout>
  )
}

export default bestManagers
